import React from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import SEO from '../../../components/layout/seo';
import ClassSubPage from "../../../components/app/class-sub-page";
import { getViewingPlatform } from '../../../libs/getViewingPlatform';

const CookingAdultsPage = ()  => {
  const { iOS, tallDevice } = getViewingPlatform();

  return (
    <AppLayout>
      <SEO title="Cooking Classes for Adult" />
      {iOS && <div style={{ height: tallDevice ? 40 : 20, backgroundColor: '#f8f4ed' }}></div>}
      <ClassSubPage type={'Adult'}/>
    </AppLayout>
  );
};

export default CookingAdultsPage;
