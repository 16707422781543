// Libs.
import React from 'react';
import styles from './command-bar.module.scss';
import { useStateValue } from '../../../store/state';
import { useClassesStaticQuery } from '../../../hooks/useClassesStaticQuery';
import { get, partialRight} from 'lodash';
import {redirectKeyPressHandler} from "../../../libs/accessibility";
import {OpenModal} from "../../../store/modal/action-creators";
import {modalTypes} from "../../../store/modal/action-types";
import FilterSelection from "./filter-selection";
import CartIcon from "./cart-icon";
import { getViewingPlatform } from '../../../libs/getViewingPlatform';
import filterIcon from '../../../images/icons/icon-filter.svg';
import selectedFilterIcon from '../../../images/icons/icon-filter-selected.svg';
const CommandBar = () => {
  const { iOS, tallDevice } = getViewingPlatform();

  const [state, dispatch] = useStateValue();
  const selectedLocals = state.classFilters.tempLocations;
  const selectedInstructs = state.classFilters.tempClassInstructors;
  const selectedDate = state.classFilters.classDates;
  const [, classFilters] = useClassesStaticQuery();
  const { locations } = classFilters;
  const filtered = locations.filter((location) => {
    return selectedLocals.includes(location.id);
  });
  const filterString =
    filtered.length > 1
      ? `${filtered.length} Locations`
      : get(filtered, '[0].name', 'All locations');

  const setFilterWindowType = (e) => {
    e.preventDefault();
    dispatch(
      OpenModal({
        id: `filterModal`,
        type: modalTypes.FILTER,
        content: <FilterSelection />,
        anchorEl: e.target,
        offsetPos: { top: 65, left: 0 },
      })
    );
  };

  const handleFilters = partialRight(setFilterWindowType);

  return (
    <div className={`${styles.commandBarContainer} ${(iOS ? (tallDevice ? styles.commandBarContainerTallIos : styles.commandBarContainerRegularIos) : styles.commandBarContainerNonIos )}`}>
      <div className={styles.commandBarFilterContainer}>
        <div className={styles.commandBarFilterKey}>Showing classes at:</div>
        <div className={styles.commandBarFilterValue}>{filterString}</div>
      </div>
      <div className={styles.commandBarButtonsContainer}>
        <div
          className={styles.commandBarButton}
          data-app={
            !!(filtered.length || selectedInstructs.length || selectedDate.length !== 0)
          }
          onClick={handleFilters}
          onKeyPress={redirectKeyPressHandler(handleFilters)}
        >
          <img src={!!(filtered.length || selectedInstructs.length || selectedDate.length !== 0) ? selectedFilterIcon  : filterIcon} alt={"filterIcon"} />
        </div>
        <CartIcon/>
      </div>
    </div>
  );
};

export default CommandBar;
