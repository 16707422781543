// Libs.
import React, { useRef, useState } from 'react';
import styles from './filter-selection.module.scss';
import filterStyles from '../../class/class-filters-common.module.scss';
import { redirectKeyPressHandler } from '../../../libs/accessibility';
import closeSymbol from '../../../images/icons/icon-close.svg';
import { useStateValue } from '../../../store/state';
import ClassFiltersActionTypes from '../../../store/classFilters/action-types';
import { CloseModal } from '../../../store/modal/action-creators';
import DateRangePicker from '@morscad/react-daterange-picker';
import { useClassesStaticQuery } from '../../../hooks/useClassesStaticQuery';
import { map } from 'lodash';
import { getViewingPlatform } from '../../../libs/getViewingPlatform';
import NavigationDrawer from '../../../hooks/useNavigationDrawer';

const FilterSelection = () => {
  const { iOS, tallDevice } = getViewingPlatform();

  const locationInp = useRef(null);
  const instructorsInp = useRef(null);
  const [state, dispatch] = useStateValue();
  const [, classFilters] = useClassesStaticQuery();
  const { instructors, locations } = classFilters;
  const tempLocations = state.classFilters.tempLocations;
  const tempInstructors = state.classFilters.tempClassInstructors;
  const [locationsSelected, setLocationSelected] = useState(
    state.classFilters.tempLocations.length > 0
  );
  const [instructorsSelected, setInstructorsSelected] = useState(
    state.classFilters.tempClassInstructors.length > 0
  );
  const [dateSelected, setDateSelected] = useState(state.classFilters.classDates.length > 0);

  const onSubmitLocations = () => {
    const opts = map(locationInp.current.querySelectorAll('option:checked'), (opt) => {
      return opt.value;
    });
    setLocationSelected(opts);
    locations.forEach((item) => {
      dispatch({
        type: ClassFiltersActionTypes.REMOVE_LOCATION,
        payload: item.id,
      });
    });
    opts.forEach((item) => {
      dispatch({
        type: ClassFiltersActionTypes.ADD_LOCATION,
        payload: item,
      });
    });
  };

  const d1 =
    state.classFilters.classDates.length > 0 ? new Date(state.classFilters.classDates[0]) : null;
  const d2 =
    state.classFilters.classDates.length > 0 ? new Date(state.classFilters.classDates[1]) : null;
  const [values, setValues] = useState([d1, d2]);
  const today = new Date();

  const onChange = (dates) => {
    dispatch({ type: ClassFiltersActionTypes.ADD_DATE_RANGE, payload: dates });
    setDateSelected(true);
    setValues(dates);
  };

  const onSubmitInstructors = () => {
    const opts = map(instructorsInp.current.querySelectorAll('option:checked'), (opt) => {
      return opt.value;
    });
    setInstructorsSelected(opts);
    instructors.forEach((item) => {
      dispatch({
        type: ClassFiltersActionTypes.REMOVE_INSTRUCTOR,
        payload: item.id,
      });
    });
    opts.forEach((item) => {
      dispatch({
        type: ClassFiltersActionTypes.ADD_INSTRUCTOR,
        payload: item,
      });
    });
  };

  const handleFiltersApply = () => {
    if (locationsSelected) {
      dispatch({
        type: ClassFiltersActionTypes.APPLY_LOCATION_FILTERS,
      });
      dispatch(CloseModal());
    }
    if (instructorsSelected) {
      dispatch({
        type: ClassFiltersActionTypes.APPLY_INSTRUCTORS_FILTERS,
      });
      dispatch(CloseModal());
    }
    if (dateSelected) {
      dispatch({
        type: ClassFiltersActionTypes.APPLY_DATE_RANGE_FILTERS,
      });
      dispatch(CloseModal());
    }
  };

  const handleFiltersClear = () => {
    if (tempLocations) {
      dispatch({
        type: ClassFiltersActionTypes.CLEAR_LOCATION_FILTERS,
      });
    }
    setDateSelected(false);
    setValues([null, null]);
    dispatch({ type: ClassFiltersActionTypes.CLEAR_DATE_RANGE_FILTERS });
    if (tempInstructors) {
      dispatch({
        type: ClassFiltersActionTypes.CLEAR_INSTRUCTORS_FILTERS,
      });
    }
  };

  const handleFiltersReset = (e) => {
    dispatch({
      type: ClassFiltersActionTypes.RESET_TEMP_LOCATIONS,
    });
    dispatch({
      type: ClassFiltersActionTypes.RESET_DATE_RANGE_FILTERS,
    });
    dispatch({
      type: ClassFiltersActionTypes.RESET_TEMP_INSTRUCTORS,
    });
    dispatch(CloseModal());
  };

  const topActionsLineApp = (
    <>
      <div className={filterStyles.classFilterMobileLine}>
        <div className={styles.classFiltersDateApplyLine}>
          <div
            role="button"
            tabIndex="0"
            onClick={handleFiltersApply}
            onKeyPress={redirectKeyPressHandler(handleFiltersApply)}
          >
            apply
          </div>
        </div>
        <div className={styles.classFiltersDateClearLine}>
          <div
            role="button"
            tabIndex="0"
            onClick={handleFiltersClear}
            onKeyPress={redirectKeyPressHandler(handleFiltersClear)}
          >
            clear
          </div>
        </div>

        <div
          role="button"
          tabIndex="0"
          className={styles.classFilterTitleCloseIcon}
          onClick={handleFiltersReset}
          onKeyPress={redirectKeyPressHandler(handleFiltersReset)}
        >
          <img src={closeSymbol} alt="Close" />
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.filterSelectionGlobalContainer}>
      {iOS && tallDevice && <div style={{ height: 30 , backgroundColor: '#FFFFFF' }}></div>}

      {topActionsLineApp}
      <div className={styles.filterSelectionLocationContainer}>
        <div className={styles.filterSelectionLocationTitle}>Location</div>
        <select
          multiple
          className={styles.filtersSelectionInputs}
          ref={locationInp}
          onChange={() => {
            onSubmitLocations();
          }}
          value={tempLocations}
          name="Locations"
        >
          {locations.map(({ id, name }) => {
            return (
              <option key={`${id}`} name={`${id}`} value={`${id}`} label={name}>
                {name}
              </option>
            );
          })}
        </select>
      </div>

      <div className={styles.classFiltersDateCalendarContainer}>
        <div className={styles.filterSelectionDateTitle}>Select Start date and End date</div>
        <DateRangePicker
          onChange={onChange}
          minDate={today}
          calendarIcon={null}
          isOpen={true}
          alwaysKeepOpen="true"
          value={values}
          calendarType="US"
        />
      </div>

      <div className={styles.classFiltersInstructorsContentContainer}>
        <div className={styles.filterSelectionInstructorsTitle}>Instructors</div>
        <select
          multiple
          className={styles.filtersSelectionInputs}
          ref={instructorsInp}
          onChange={() => {
            onSubmitInstructors();
          }}
          value={tempInstructors}
          name="Instructors"
        >
          {instructors.map(({ id, name }) => {
            return (
              <option key={`${id}`} value={`${id}`} name={`${id}`} label={name}>
                {name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterSelection;
