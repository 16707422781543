// Libs
import React from 'react';
// Assets.
import styles from './class-sub-page.module.scss';
import CommandBar from "./classes-command-bar/command-bar";
import SiteLink from "../common/site-link";
import locale from '../../locale/locale';
import appAliases from '../../data/appAliases';
import iconArrowSvg from "../../images/icons/icon-arrow.svg";
import loadable from "@loadable/component";
import PagePreloader from "../common/loaders/page-preloader";

const ClassSubPage = ({type}) => {
  const ClassSubList = loadable(() => import('./loft/class-sub-list'), {
    fallback: (
      <div style={{ position: 'relative' }}>
        <PagePreloader />
      </div>
    ),
  });
  const titles = {Adult: 'For Adults', Kids: 'For Kids'};
  return (
    <div className={styles.classSubPage}>
      <CommandBar/>
      <div className={styles.classSubPageContainer}>
        <SiteLink href={appAliases.appClasses}><img className={styles.classSubPageIcon} src={iconArrowSvg} alt="Placeholder"/> {locale.classBooking.buttons.allClassesButton}</SiteLink>
        <h1 className={styles.classSubPageTitle}>{titles[type]}</h1>
        <ClassSubList type={type}/>
      </div>
    </div>
  );
};

export default ClassSubPage;
